import { Component, OnInit, ViewChild  } from '@angular/core';
import { Router} from '@angular/router';
import { setTheme } from 'ngx-bootstrap/utils';
import { DatePipe } from '@angular/common';
import { ScrollToService, ScrollToConfigOptions } from '@nicky-lenaers/ngx-scroll-to';
import { TabsetComponent } from 'ngx-bootstrap/tabs';



@Component({
  selector: 'app-check-availability',
  templateUrl: './check-availability.component.html',
  styleUrls: ['./check-availability.component.scss']
})
export class CheckAvailabilityComponent implements OnInit {

  @ViewChild('staticTabs', { static: false }) staticTabs: TabsetComponent;

  public bsInlineValue = new Date();
  public bsInlineValue_sub = <any>'';
  public adult : number = 2;
  public child : number = 0;
  public infant : number = 0;
  public max_adult = <any>'';
  public max_child = <any>'';
  public max_infant = <any>'';
  public min_adult : number = 2;
  public min_Datetour = new Date('2021-01-05');
  public max_Datetour = new Date('2021-12-26');
  public min_Datesubtour = new Date();
  public max_Datesubtour = new Date();
  public disabledDates = [];
  public Datetimetour_1 = <any>'';
  public bsInlineRangeValue: Date[];
  public Date_maintour = <any>'';
  public Date_subtour = <any>'';
  public privacy = <any>'';
  public TourTime = <any>'';
  public adult_price = <any>'N/A';
  public child_price = <any>'N/A';
  public total_price = <any>'';
  public type_subTour : boolean = false;
  public tour_name = 'Inthanon National Park And City Temple & Museum';
  public Bookingavaliability = [];
  public message_adult_price : string = <any>'';
  public message_child_price : string = <any>'';

  constructor( private scrollToService: ScrollToService, private datePipe: DatePipe ) { 
    setTheme('bs4');
  }

  // ------------ [start] check Participants value -------------------
  change_adult(value: number): void {
    this.min_adult = 2;
    this.adult = value;
      if(this.adult == 9){
          this.max_adult = 0;
          this.max_child = 0;
          this.max_infant = 0;
      }else if(this.adult + this.child == 9){
          this.max_adult = 0;
          this.max_child = 0;
          this.max_infant = 0;
      }else if(this.adult + this.infant == 9){
          this.max_adult = 0;
          this.max_child = 0;
          this.max_infant = 0;
      }else if(this.adult + this.child + this.infant == 9){
          this.max_adult = 0;
          this.max_child = 0;
          this.max_infant = 0;
      }else{
          this.max_adult = 9;
          this.max_child = 9;
          this.max_infant = 9;
      }

      if( this.child >= 1 ){
          this.min_adult =1;
      }

    if(this.privacy == 'private'){
      if( this.adult <= 3){
        this.adult_price = 3500;
        this.message_adult_price = '2-3 pax : 3500';
      }else if(this.adult >= 4 && this.adult <= 5){
        this.adult_price = 2900;
        this.message_adult_price = '4-5 pax : 2900';
      }else if(this.adult <= 9){
        this.adult_price = 2500;
        this.message_adult_price = '6-9 pax : 2500';
      }
    }
      this.total_price = (this.adult*this.adult_price)+(this.child*this.child_price)+(this.infant*0);
  }

  change_child(value: number): void {
    this.child = value;
    this.min_adult = 2;
      if(this.child == 9){
          this.max_adult = 0;
          this.max_child = 0;
          this.max_infant = 0;
      }else if(this.child + this.adult == 9){
          this.max_adult = 0;
          this.max_child = 0;
          this.max_infant = 0;
      }else if(this.child + this.infant == 9){
          this.max_adult = 0;
          this.max_child = 0;
          this.max_infant = 0;
      }else if(this.adult + this.child + this.infant == 9){
          this.max_adult = 0;
          this.max_child = 0;
          this.max_infant = 0;
      }else{
          this.max_adult = 9;
          this.max_child = 9;
          this.max_infant = 9;
    }

      if(this.child >= 1){
          this.min_adult = 1;
      }else if(this.child == 0 && this.adult ==1){
          this.adult = 2;
      }

  if(this.privacy == 'private'){
    console.log(this.privacy);
    if( this.child <= 3){
      this.child_price = 2100;
      this.message_child_price = '2-3 pax : 2100';
    }else if(this.child >= 4 && this.child <= 5){
      this.child_price = 1750;
      this.message_child_price = '4-5 pax : 1750';
    }else if(this.child <= 9){
      this.child_price = 1500;
      this.message_child_price = '6-9 pax : 1500';
    }
  }
    this.total_price = (this.adult*this.adult_price)+(this.child*this.child_price)+(this.infant*0);
  }

  change_infant(value: number): void {
    this.infant = value;
    this.min_adult = 2;
    if(this.infant == 9){
      this.max_adult = 0;
      this.max_child = 0;
      this.max_infant = 0;
  }else if(this.infant + this.adult == 9){
      this.max_adult = 0;
      this.max_child = 0;
      this.max_infant = 0;
  }else if(this.infant + this.child == 9){
      this.max_adult = 0;
      this.max_child = 0;
      this.max_infant = 0;
  }else if(this.adult + this.child + this.infant == 9){
      this.max_adult = 0;
      this.max_child = 0;
      this.max_infant = 0;
  }else{
      this.max_adult = 9;
      this.max_child = 9;
      this.max_infant = 9;
    }
    this.total_price = (this.adult*this.adult_price)+(this.child*this.child_price)+(this.infant*0);
  }
// ------------ [end] check Participants value -------------------

selectTab(tabId: number) {
  this.staticTabs.tabs[tabId].active = true;
}

triggerScrollTo() {
  const config: ScrollToConfigOptions = {
    target: 'check-date-sub-tour'
  };
  this.scrollToService.scrollTo(config);
}

change_date(){

  // ------- DateTime ---------
  let newDate = this.datePipe.transform(this.bsInlineValue, 'yyyy-MM-dd');
  if(this.Date_maintour !== newDate || this.Date_maintour == null){
  this.max_Datesubtour.setDate(this.bsInlineValue.getDate()+2);
  this.max_Datesubtour.setMonth(this.bsInlineValue.getMonth());
  this.max_Datesubtour.setFullYear(this.bsInlineValue.getFullYear());
  this.min_Datesubtour.setDate(this.bsInlineValue.getDate()-2);
  this.min_Datesubtour.setMonth(this.bsInlineValue.getMonth());
  this.min_Datesubtour.setFullYear(this.bsInlineValue.getFullYear());
  this.bsInlineValue_sub = new Date(newDate);
  this.bsInlineValue_sub = new Date(this.bsInlineValue_sub.getFullYear(), this.bsInlineValue_sub.getMonth(), this.bsInlineValue_sub.getDate()+1);
  this.Date_maintour = newDate;
  }
  if(this.disabledDates[0] == null){
    this.disabledDates.push(new Date(this.datePipe.transform(this.bsInlineValue, 'yyyy-MM-dd')));
  }else{
    this.disabledDates[0] = new Date(this.datePipe.transform(this.bsInlineValue, 'yyyy-MM-dd'));
  }

  // -------- privacy & Time -----------
  // console.log(this.privacy+'/'+this.TourTime);

  // -------- check Participants ----------
  // console.log(this.adult);
  // console.log(this.child);
  // console.log(this.infant);
}

onValueChange($event){
  this.bsInlineValue_sub  = $event;
  console.log(this.bsInlineValue_sub);
}

// ------ check radio join or private ---------
check_type_join(){
    this.privacy = 'join';
    this.TourTime = 'FullDay 08:30 A.M. - 17:00 P.M.';
    this.adult_price = 2000;
    this.child_price = 1200;
    this.cul_Participants();
    this.type_subTour = false;
    // this.min_adult = 2;
}

check_type_private(){
    this.privacy = 'private';
    this.TourTime = 'FullDay 08:30 A.M. - 17:00 P.M.';
    this.adult_price = 3500;
    this.child_price = 2100;
    // this.min_adult = 2;
    // check adult pax
    if( this.adult <= 3){
      this.adult_price = 3500;
      this.message_adult_price = '2-3 pax : 3500';
    }else if(this.adult >= 4 && this.adult <= 5){
      this.adult_price = 2900;
      this.message_adult_price = '4-5 pax : 3500';
    }else if(this.adult <= 9){
      this.message_adult_price = '6-9 pax : 3500';
      this.adult_price = 2500;
    }
    // check child pax
    if( this.child <= 3){
      this.child_price = 2100;
      this.message_child_price = '2-3 pax : 2100';
    }else if(this.child >= 4 && this.child <= 5){
      this.child_price = 1750;
      this.message_child_price = '2-3 pax : 1750';
    }else if(this.child <= 9){
      this.child_price = 1500;
      this.message_child_price = '2-3 pax : 1500';
    }
    this.cul_Participants();
    this.type_subTour = true;
}

// ---------- cul Participants --------
cul_Participants(){
    if(this.adult !== null || this.child !== null){
      this.total_price = (this.adult*this.adult_price)+(this.child*this.child_price)+(this.infant*0);
    }
}

// ------------ set Data to Sessionstorage -----------
setDataBooking(){
    let setBookingavaliability = {
      tourname : this.tour_name,
      code_T1 : 'TC-08',
      code_T2 : 'TC-02',
      privacy : this.privacy,
      adult : this.adult,
      child : this.child,
      infant : this.infant,
      adultprice : this.adult_price,
      childprice : this.child_price,
      totalprice : this.total_price,
      datetravel_T1 : this.bsInlineValue.toDateString(),
      datetravel_T2 : this.bsInlineValue_sub.toDateString()
    }
    if( this.Bookingavaliability[0] == null){
          this.Bookingavaliability.push(setBookingavaliability);
    }else{
      this.Bookingavaliability = [];
        this.Bookingavaliability.push(setBookingavaliability);
    }
    sessionStorage.setItem("Bookingavaliability",JSON.stringify(this.Bookingavaliability));
}

  ngOnInit() {
    console.log(this.bsInlineValue);
    this.max_adult = 9;
    this.max_child = 9;
    this.max_infant = 9;
    // this.check_type_private();
  }

}
