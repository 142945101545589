import { Component, OnInit } from '@angular/core';
import { IPayPalConfig, ICreateOrderRequest } from 'ngx-paypal';
import { Http, RequestOptions } from '@angular/http';
import { Router, ActivatedRoute } from '@angular/router';
import { map } from "rxjs/operators";

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {

    public payment_id = <any>'';
    public getDataPayment = {
        PaymentId : <any>'',
        PrommotionTittle : <any>'',
        TotalPrice : <any>''
    }
    public getDataPayment_ = <any>''; 
    public updateSuccess = <any>''; 
    private routeSub = <any>'';

  constructor(
    private http: Http,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  // ------------------- Paypal payment ---------------
  public payPalConfig ? : IPayPalConfig;

    private initConfig(): void {
        this.payPalConfig = {
            currency: 'THB',
            clientId: 'AUjxBnQF9juFjhnywsOuWZKDqsXAtXOjbOvZJwwU8ISufMfE67iyw57-kGHziQ6nHXAzotKgqDo8Ongc',
            createOrderOnClient: (data) => < ICreateOrderRequest > {
                intent: 'CAPTURE',
                purchase_units: [{
                    amount: {
                        currency_code: 'THB',
                        value: this.getDataPayment.TotalPrice,
                        breakdown: {
                            item_total: {
                                currency_code: 'THB',
                                value: this.getDataPayment.TotalPrice
                            }
                        }
                    },
                    items: [{
                        name: this.getDataPayment.PrommotionTittle +' ID Payment - '+ this.getDataPayment.PaymentId,
                        quantity: '1',
                        category: 'DIGITAL_GOODS',
                        unit_amount: {
                            currency_code: 'THB',
                            value: this.getDataPayment.TotalPrice,
                        },
                    }]
                }]
            },
            advanced: {
                commit: 'true'
            },
            style: {
                label: 'paypal',
                layout: 'vertical'
            },
            onApprove: (data, actions) => {
                console.log('onApprove - transaction was approved, but not authorized', data, actions);
                actions.order.get().then(details => {
                    console.log('onApprove - you can get full order details inside onApprove: ', details);
                });

            },
            onClientAuthorization: (data) => {
                console.log('onClientAuthorization - you should probably inform your server about completed transaction at this point', data);
                    let payment_id_ = atob(this.payment_id);
                    // payment_id_ = payment_id_.substring(9, 13);
                    if(payment_id_ == this.getDataPayment.PaymentId){
                            this.updatepaymentsuccess();
                        }else{
                            console.log('Error !!! Payment')
                        }
                // this.showSuccess = true;
            },
            onCancel: (data, actions) => {
                console.log('OnCancel', data, actions);
                // this.showCancel = true;

            },
            onError: err => {
                console.log('OnError', err);
                // this.showError = true;
            },
            onClick: (data, actions) => {
                console.log('onClick', data, actions);
                // this.resetStatus();
            }
        };
    }

    getpaymentId(){
        // this.payment_id = sessionStorage.getItem('payment_id');
        // this.payment_id = this.payment_id[0].id;
        // console.log(this.payment_id+'1111111111');
        // console.log(this.payment_id.id+'222222222');
        let payment_ = {
            paymentId : this.payment_id
        }
        //get total Price By Payment_id
        let url = "http://localhost:8000/api/Promotions/Payment/getDataPaymentId";
        let options = new RequestOptions();
                return this.http.post(url,payment_,options)
                    .pipe(map(res => res.json()))
                    .subscribe(
                      data => [
                        this.getDataPayment_ = data,
                        this.setPayment(),
                        console.log(this.getDataPayment_)
                      ],
                      err => [
                        console.log(err)
                      ]
                    );
    }

    setPayment(){
        if(this.getDataPayment_){
        this.getDataPayment.PaymentId = this.getDataPayment_.PaymentId;
        this.getDataPayment.PrommotionTittle = this.getDataPayment_.PrommotionTittle;
        this.getDataPayment.TotalPrice = this.getDataPayment_.TotalPrice;
        }else{
            console.log('Error get Data');
        }
    }

    updatepaymentsuccess(){
        let payment_ = {
            paymentId : this.payment_id
        }
        //update payment success
        let url = "http://localhost:8000/api/Promotions/Payment/updatePayPromotionId";
        let options = new RequestOptions();
                return this.http.post(url,payment_,options)
                    .pipe(map(res => res.json()))
                    .subscribe(
                      data => [
                          this.updateSuccess = data,
                          console.log(this.updateSuccess),
                          this.router.navigate(['booking/invoice/'+this.payment_id])
                      ],
                      err => [
                        console.log(err)
                      ]
                    );
    }


  ngOnInit(): void {
    this.payment_id = this.route.snapshot.paramMap.get('id');
    this.getpaymentId();
    this.initConfig();
  }

//   ngOnDestroy() {
//     this.payment_id.unsubscribe();
//   }

}