<div class="container">
  <div class="row">
      <div class="col-md-6 col-sm-6 col-xs-6" style="margin-bottom:20px;">
        <img src="../../../../assets/images/tc-b.png" width="50%" height="auto" class="d-inline-block align-top" alt="touringcenterLogo">
      </div>
      <div class="col-md-6 col-sm-6 col-xs-6 createdate" align="right">
          <h5>Created date : </h5>
          <h5>Status : <span style='color:green;'><b>Paid</b></span></h5>
          <p class="alert alert-success no-print" style="float:right;"><i class="fa fa-check"></i> Thanks for booked with us, Please check your e-mail.</p>
      </div>
  </div>

  <!--  Customer Information  -->
  <div class="row">
  <div class="col-md-12">
      <div class="table-responsive">
          <table class="table table-bordered" style="font-size:16px;">
              <tr><th style="background:#eb1c23;color:white;width:210px;"><i class="fa fa-book" aria-hidden="true"></i> Booking</th><th style="background:black;color:white;text-align:right;" colspan="2"></th></tr>
              <tr>
                  <th>Program</th>
                  <td colspan="2"><span style="color:red">()</span></td>
              </tr>
              <tr>
                  <th>Traveling date</th>
                  <td></td>
                  <td></td>

              </tr>
              <tr>
                  <th>No. of Traveller</th>
                  <td width="50%" style="border-right:1px solid #ccc;"> Adult x  = ฿</td>
                  <td> Child x  = ฿</td>
              </tr>
              <tr>
                  <th>Total</th>
                  <td><b style="font-size:18px;color:green;"> ฿</b> (extra charge: ฿)</td>
                  <td><b>Status : <span style="color:green">Paid</span></b></td>
              </tr>
          </table>
      </div>
  </div>
  </div> <!-- row -->

<div class="row">
  <div class="col-md-12">
      <div class="table-responsive">
          <table class="table table-bordered" style="font-size:16px;">
              <tr><th style="background:#eb1c23;color:white;"><i class="fa fa-user" aria-hidden="true"></i> Customer Details</th><th style="background:black;color:white;" colspan="5"></th></tr>
              <tr style="background:#f7f7f7;">
                  <td style="width:210px"><b>FullName</b></td>
                  <td width="30%" colspan="3"><span style="color:blue;">(Primary)</span></td>
                  <td>Firstname :  </td>
                  <td>lastname : </td>
              </tr>
<!-- phone | mail -->
              <tr style="background:#f7f7f7;">
                  <th>Phone</th>
                        <td colspan="3"></td>
                  <th>E-mail</th>
                        <td colspan="3"></td>
              </tr>
              <!-- <tr style="background:#f7f7f7;">
                  <th>Hotel</th>
              <td colspan="6"></td>
              </tr> -->
          </table>
      </div>
  </div>
  </div>
  <p align="center"
   style="margin-top: 10px;padding:5px 15px;">The listed price does not include a 500THB & 1,000THB surcharge for pick up and drop off in
   hotel outside the city centre <br>
   (6 km. from the 3 king monument). The pick up surcharge is required by our local
   supplier and is not within our control.
  </p>

  <p align="center"
   style="margin-top: 10px;padding:5px 15px;"><b>Terms and Conditions</b> <br>
   All sales are final and incur 100% cancellation penalties.
  </p>


<!--  Paypal , FDF  -->
  <div id="tour_box" align="right" style="padding-right:10px;" class="no-print">
          <center>
              <a class="btn btn-info" href="http://tour-in-chiangmai.com/destroy.php"><i class="fa fa-home fa-2x"></i><font size='4'> Home </font></a>
              <button class="btn btn-danger" onclick="window.print();"><i class="fa fa-print fa-2x"></i><font size='4'> Print </font></button>
          </center>       
  </div>
  <div class='clearfix'></div>
  <hr width="100%">

  <p align="center">
      14 1<sup>st</sup> Floor, Ratchadamnoen Rd., Soi 5, Sriphum, Muang, Chiang Mai 50200 Thailand<br/>
      Mobile : +66(0)88 258 5817 Tel : +66(0)53 289 644 Fax : +66(0)53 289 659<br/>
      E-mail : reservations@touringcnx.com, touringcenter@hotmail.com<br/>
      Operating Hour : 08.00 - 20.00 (Daily)
  </p>
</div>