<!-- <nav class="navbar navbar-dark bg-dark bg-faded">
  <a class="navbar-brand" href="#">
    <img src="../../../../assets/images/tc-w.png" width="17%" height="auto" class="d-inline-block align-top" alt="touringcenterLogo">
  </a>

</nav> -->

<nav class="navbar navbar-expand-lg navbar-dark bg-dark">
  <img src="../../../../assets/images/tc-w.png" width="17%" height="auto" class="d-inline-block align-top" alt="touringcenterLogo">

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="nav navbar-nav ml-auto">
      <li class="nav-item active">
        <a class="nav-link" href="https://tour-in-chiangmai.com/" target="_blank">Home <span class="sr-only">(current)</span></a>
      </li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <li class="nav-item">
        <a class="nav-link" href="https://tour-in-chiangmai.com/about.php" target="_blank">About</a>
      </li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <li class="nav-item">
        <a class="nav-link" href="https://tour-in-chiangmai.com/tours.php" target="_blank">Tours</a>
      </li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <li class="nav-item">
        <a class="nav-link" href="https://tour-in-chiangmai.com/Activity.php" target="_blank">Activity</a>
      </li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <li class="nav-item">
        <a class="nav-link" href="https://tour-in-chiangmai.com/galleries.php" target="_blank">Gallery</a>
      </li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <li class="nav-item">
        <a class="nav-link" href="https://tour-in-chiangmai.com/reviews.php" target="_blank">Review</a>
      </li>
    </ul>
  </div>
</nav>
