<app-menu-bar></app-menu-bar>

<section>
    <div class="contanier">
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 breadcrumb_">
              <ul>
                <li class="home"><a href="https://tour-in-chiangmai.com/">Home</a></li>
                <li> / </li>
                <li>Tour Promotion</li>
                <li> / </li>
                <li><a [routerLink]="['/']">Inthanon National Park And City Temple & Museum</a></li>
              </ul>
              <hr>
        </div>
    </div>
    <div class="contanier centered_">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
         <h1>Tell us who you are and how to contact you</h1>
           <hr>
            <div class="row flex-column-reverse flex-md-row">
                <div class="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
                    <div class="shadow p-5 mb-5 bg-white rounded">
                        <!-- first name -->
                            <mat-form-field class="example-full-width" style="width: 100%;" appearance="outline">
                              <mat-label>First name</mat-label>
                              <input matInput [(ngModel)]="information_guest.firstname"  (keyup)="check_input_info()">
                              <!-- <input matInput [(ngModel)]="information_guest.firstname" (keyup)="check_input_info()"> -->
                              <span *ngIf="error.firstname == false" style="color:red; font-size:11px;">Please enter First name</span>
                            </mat-form-field>
                        <!-- last name -->
                            <mat-form-field class="example-full-width" style="width: 100%;" appearance="outline">
                                <mat-label>Last name</mat-label>
                                <input matInput [(ngModel)]="information_guest.lastname" (keyup)="check_input_info()" >
                                <span *ngIf="error.lastname == false" style="color:red; font-size:11px;">Please enter last name</span>
                            </mat-form-field>
                        <!-- email -->
                              <mat-form-field class="example-full-width" style="width: 100%;" appearance="outline">
                                <mat-label>Your email address</mat-label>
                                <input matInput [(ngModel)]="information_guest.email" (keyup)="check_input_info()" >
                                <span *ngIf="error.email == false" style="color:red; font-size:11px;">Please enter your email</span>
                            </mat-form-field>
                        <!-- phone -->
                              <mat-form-field class="example-full-width" style="width: 100%;" appearance="outline">
                                <mat-label>Your phone number</mat-label>
                                <input matInput [(ngModel)]="information_guest.phone" (keyup)="check_input_info()" >
                                <span *ngIf="error.phone == false" style="color:red; font-size:11px;">Please enter your phone number</span>
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <button class="btn btn-success btn-to-payment" (click)="check_info_brfore_sent()" type="button">Continue to payment<i *ngIf="reload == true" class="fa fa-refresh fa-spin" style="font-size:18px; margin-left:10px;"></i></button>
                            <!-- <button class="btn btn-success btn-to-payment" (click)="send_to_booking()" type="button">Continue to payment</button> -->
                        </div>
                </div>
                <div class="col-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
                    <div *ngFor="let databooking of getDataBooking" class="shadow p-5 mb-5 rounded bg-color-card">
                            <h2 class="text-center">Please review your order<span style="text-transform: uppercase; color:red;">    ({{databooking.privacy}} Tour)</span></h2>
                            <hr>
                            <h3>Inthanon National Park</h3>
                                <p>{{databooking.datetravel_T1}} @ 08:30 A.M. - 17:00 P.M.</p>
                            <hr>
                            <h3>City Temple & Museum</h3>
                            <p>{{databooking.datetravel_T2}} @ 01.30 P.M. – 05.00 P.M.</p>
                            <hr>
                            <!-- ------  adult price   ----------- -->
                            <div class="row">
                                   <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                            <h3>{{databooking.adult}} x Adults</h3>
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                        <h3 style="float:right">{{databooking.adultprice}} THB</h3>
                                    </div> 
                            </div>
                            <!-- ------  child price   ----------- -->
                             <div *ngIf="bookingavaliability.child !== 0" class="row">
                                   <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                            <h3>{{databooking.child}} x Childs</h3>
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                        <h3 style="float:right">{{databooking.childprice}} THB</h3>
                                    </div> 
                            </div>
                            <!-- ------  infant price   ----------- -->
                            <div *ngIf="bookingavaliability.infant !== 0" class="row">
                                <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                         <h3>{{databooking.infant}} x Infants</h3>
                                 </div>
                                 <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                 </div> 
                            </div>
                            <hr>
                            <!-- ------- totel price --------------- -->
                            <div class="row">
                                <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                         <h1>Total</h1>
                                 </div>
                                 <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                    <h1 style="float:right">{{databooking.totalprice}} THB</h1>
                                 </div> 
                            </div>
                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style=" text-align: center;">
                                <span style="font-size: 20px;">we accept paypal payment</span><img class="img-icon-paypal" src="../../../assets/images/brands-and-logotypes.png">
                            </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>