import { Component, OnInit } from '@angular/core';
import { Http, RequestOptions } from '@angular/http';
import { Router, ActivatedRoute } from '@angular/router';
import { map } from "rxjs/operators";


@Component({
  selector: 'app-information-guest',
  templateUrl: './information-guest.component.html',
  styleUrls: ['./information-guest.component.scss']
})
export class InformationGuestComponent implements OnInit {

  public information_guest = {
      firstname : <any>'',
      lastname : <any>'',
      email : <any>'',
      phone : <any>''
};

  public bookingavaliability = {
      tourname : <any>'',
      code_T1 : <any>'',
      code_T2 : <any>'',
      privacy : <any>'',
      adult : <any>'',
      child : <any>'',
      infant : <any>'',
      adultprice : <any>'',
      childprice : <any>'',
      totalprice : <any>'',
      datetravel_T1 : <any>'',
      datetravel_T2 : <any>''
  }

  public error  = {
      firstname : true,
      lastname : true,
      email : true,
      phone : true
  }

public getDataBooking  = <any>'';
public setData = <any>'';
public addInformation = <any>'';
public reload : boolean = false;

  constructor(
    private http: Http,
    private router: Router,
  ) { }

  getitemDatabooking(){
    //--------------- info guest
    let information_guest_  = JSON.parse(sessionStorage.getItem('information-guest'));
    if(information_guest_){
      if(information_guest_){
        this.information_guest.firstname = information_guest_.firstname;
        console.log(this.information_guest.firstname);
        this.information_guest.lastname = information_guest_.lastname;
        this.information_guest.email = information_guest_.email;
        this.information_guest.phone = information_guest_.phone;
      }
    }
    // -------------- data booking
    this.getDataBooking = JSON.parse(sessionStorage.getItem('Bookingavaliability'));
        this.setDataBooking();
  }

  setDataBooking(){
    this.bookingavaliability.tourname = this.getDataBooking[0].tourname;
    this.bookingavaliability.code_T1 = this.getDataBooking[0].code_T1;
    this.bookingavaliability.code_T2 = this.getDataBooking[0].code_T2 ;
    this.bookingavaliability.privacy = this.getDataBooking[0].privacy;
    this.bookingavaliability.adult = this.getDataBooking[0].adult;
    this.bookingavaliability.child = this.getDataBooking[0].child ;
    this.bookingavaliability.infant = this.getDataBooking[0].infant ;
    this.bookingavaliability.adultprice = this.getDataBooking[0].adultprice ;
    this.bookingavaliability.childprice = this.getDataBooking[0].childprice;
    this.bookingavaliability.totalprice = this.getDataBooking[0].totalprice ;
    this.bookingavaliability.datetravel_T1 = this.getDataBooking[0].datetravel_T1;
    this.bookingavaliability.datetravel_T2 = this.getDataBooking[0].datetravel_T2
  }


  check_input_info(){
      if(this.information_guest.firstname !== ''){
        this.error.firstname = true;
      }
      if(this.information_guest.lastname !== ''){
        this.error.lastname = true;
      }
      if(this.information_guest.email !== ''){
        this.error.email = true;
      }
      if(this.information_guest.phone !== ''){
        this.error.phone = true;
      }
  }

  check_info_brfore_sent(){
    if(this.information_guest.firstname == ''){
      this.error.firstname = false;
  }
  if(this.information_guest.lastname == ''){
      this.error.lastname = false;
  }
  if(this.information_guest.email == ''){
      this.error.email = false;
  }
  if(this.information_guest.phone == ''){
      this.error.phone = false;
    }
  if(this.information_guest.firstname !== '' && this.information_guest.lastname !== '' && this.information_guest.email !== '' && this.information_guest.phone !== '' ){
          sessionStorage.setItem('information-guest' , JSON.stringify(this.information_guest));
          this.send_to_booking();
          console.log(this.setData);
        (async () => { 
          this.reload = true;
          await this.delay(5000);
          this.router.navigate(['booking/payment/'+this.addInformation.Payment]);
        })();
      }
  }

  send_to_booking(){
    this.setDatabooking();
    this.sendDataPromotionBook();
  }

  public setDatabooking(){
    this.setData = {
        "guestInfo" : {
            "firstname" : this.information_guest.firstname,
            "lastname"  : this.information_guest.lastname,
            "email"     : this.information_guest.email,
            "tel"       : this.information_guest.phone
        },
        "bookingInfo" : {
            "promotion"        : "1",
            "promotion_tittle" : "Inthanon National Park And City Temple & Museum (Get 1 Free 1)",
            "privacy"          : this.bookingavaliability.privacy,
            "adultPax"         : this.bookingavaliability.adult,
            "childPax"         : this.bookingavaliability.child,
            "infantPax"        : this.bookingavaliability.infant,
            "adultPrice"       : this.bookingavaliability.adultprice,
            "childPrice"       : this.bookingavaliability.childprice,
            "totalPrice"       : this.bookingavaliability.totalprice,
        },
        "dateTravel" : [ 
          {
            "tour_code"   : this.bookingavaliability.code_T1,
            "tour_tittle": "Inthanon National Park",
            "date_travel"  : this.bookingavaliability.datetravel_T1,
            "tour_travel_time"    : "Full Day",
            "tour_time"  :  "08.30 a.m. – 05.00 p.m."
          },
          {
            "tour_code"       : this.bookingavaliability.code_T2,
            "tour_tittle": "City Temple & Museum",
            "date_travel"      : this.bookingavaliability.datetravel_T2,
            "tour_travel_time"    : "Afternoon",
            "tour_time"  :  "01.30 p.m. – 05.00 p.m."
          }
        ]
    }
  }

  sendDataPromotionBook(){
    // post to API
    // let url = "http://localhost:8000/api/Promotions/GetFree/AddBooking";
    let url = 'http://api.tourinchiangmai.com/api/Dashboard/Member/Profile';
    let options = new RequestOptions();
    return this.http.post(url,this.setData,options)
                    .pipe(map(res => res.json()))
                    .subscribe(
                      data => [
                          this.addInformation = data.data,
                          console.log(this.addInformation),
                          // this.setPayment()
                      ],
                      err => [
                        console.log(err)
                      ]
                    );
  }

  setPayment(){
    // let payment_id = this.addInformation.Payment
    // sessionStorage.setItem('payment_id', payment_id);
  }

  delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }
  
  ngOnInit(): void {
      this.getitemDatabooking();      
  }
}