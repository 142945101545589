import { Component, OnInit } from '@angular/core';
import { IPayPalConfig, ICreateOrderRequest } from 'ngx-paypal';
import { Http, RequestOptions } from '@angular/http';
import { Router, ActivatedRoute } from '@angular/router';
import { map } from "rxjs/operators";

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss']
})
export class InvoiceComponent implements OnInit {


  public payment_id = <any>'';
  public checkActive = <any>'';

  constructor(
    private http: Http,
    private router: Router,
    private route: ActivatedRoute
  ) { }



  checkpaymentid(){
          let payment_ = {
            paymentId : this.payment_id
        }
        //check payment active
        let url = "http://localhost:8000/api/Promotions/Payment/checkPaymentIdActive";
        let options = new RequestOptions();
                return this.http.post(url,payment_,options)
                    .pipe(map(res => res.json()))
                    .subscribe(
                      data => [
                          this.checkActive = data,
                          console.log(this.checkActive)
                          // this.router.navigate(['booking/invoice/'+this.payment_id])
                      ],
                      err => [
                        console.log(err)
                      ]
                    );
  }

  ngOnInit(): void {
    this.payment_id = this.route.snapshot.paramMap.get('id');
    this.checkpaymentid();
  }

}
