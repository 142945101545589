<app-menu-bar></app-menu-bar>
<section>
    <div class="contanier">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 breadcrumb_">
          <ul>
            <li class="home"><a href="https://tour-in-chiangmai.com/">Home</a></li>
            <li> / </li>
            <li>Booking</li>
            <li> / </li>
            <li>Update</li>
          </ul>
          <hr>
      </div>
    </div>
    <div class="contanier centered_">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
         <h1>Update Informations and new Datetime Travel </h1>
           <hr>
            <div class="row flex-column-reverse flex-md-row">
                <div class="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
                    <div class="shadow p-5 mb-5 bg-white rounded">
                        <!-- first name -->
                            <mat-form-field class="example-full-width" style="width: 100%;" appearance="outline">
                              <mat-label>First name</mat-label>
                              <input [disabled]="is_edit==false" matInput [(ngModel)]="information_guest.firstname" (keyup)="check_input_info()">
                              <span *ngIf="error.firstname == false" style="color:red; font-size:11px;">Please enter First name</span>
                            </mat-form-field>
                        <!-- last name -->
                            <mat-form-field class="example-full-width" style="width: 100%;" appearance="outline">
                                <mat-label>Last name</mat-label>
                                <input [disabled]="is_edit==false"  matInput [(ngModel)]="information_guest.lastname" (keyup)="check_input_info()" >
                                <span *ngIf="error.lastname == false" style="color:red; font-size:11px;">Please enter last name</span>
                            </mat-form-field>
                        <!-- email -->
                              <mat-form-field class="example-full-width" style="width: 100%;" appearance="outline">
                                <mat-label>Your email address</mat-label>
                                <input [disabled]="is_edit==false"  matInput [(ngModel)]="information_guest.email" (keyup)="check_input_info()" >
                                <span *ngIf="error.email == false" style="color:red; font-size:11px;">Please enter your email</span>
                            </mat-form-field>
                        <!-- phone -->
                              <mat-form-field class="example-full-width" style="width: 100%;" appearance="outline">
                                <mat-label>Your phone number</mat-label>
                                <input [disabled]="is_edit==false"  matInput [(ngModel)]="information_guest.phone" (keyup)="check_input_info()" >
                                <span *ngIf="error.phone == false" style="color:red; font-size:11px;">Please enter your phone number</span>
                            </mat-form-field>
                        <!-- edit -->
                            <p class="pointer" style="color:violet; text-align: center;" (click)="is_edit_()">Edit</p>
                        </div>
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <!-- <button class="btn btn-success btn-to-payment" [routerLink]="['/booking/payment']" type="button">Continue to payment</button> -->
                            <button class="btn btn-success btn-to-payment" (click)="check_info_brfore_sent()" type="button">Update Booking</button>
                        </div>
                </div>
                <div class="col-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
                    <label><h2>Inthanon National Park :</h2></label>
                        <div class="ol-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <input type="text"
                            placeholder="Datepicker"
                            class="form-control"
                            bsDatepicker
                            (bsValueChange)="onValueChange()"
                            [minDate]="min_Datetour" 
                            [maxDate]="max_Datetour"
                            [(bsValue)]="bsValue_main"
                            [bsConfig]="{ isAnimated: true }">
                        </div><br>
                    <label><h2>City Temple & Museum :</h2></label>
                        <div class="ol-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <input type="text" 
                            placeholder="Datepicker"
                            class="form-control"
                            bsDatepicker
                            [minDate]="min_Datesubtour" 
                            [maxDate]="max_Datesubtour" 
                            [datesDisabled]="disabledDates"
                            [(bsValue)]="bsValue_sub"
                            [bsConfig]="{ isAnimated: true }">
                        </div><br>
                        <hr class="new2">
                </div>
            </div>
        </div>
    </div><br>
    <hr><br>
    <div style="text-align: center;">
        <p>14 1st Floor, Ratchadamnoen Rd., Soi 5, Sriphum, Muang, Chiang Mai 50200 Thailand</p>
        <p>Mobile : +66(0)88 258 5817 Tel : +66(0)53 289 644 Fax : +66(0)53 289 659</p>
        <p>E-mail : <a href = "mailto: reservations@touringcnx.com">reservations@touringcnx.com</a>, <a href = "mailto: touringcenter@hotmail.com">touringcenter@hotmail.com</a></p>
        <p>Operating Hour : 08.00 - 20.00 (Daily)</p>
      </div>
</section>