<!-- check avaliability  -->
<div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xd-12">
  <div class="bg-book">
  </div>
</div>

<div class="shadow p-3 mb-5 rounded" style="background-color: #f2f9f2;">
<tabset #staticTabs>
<tab  heading="Inthanon National Park">
<section id="check-date-main-tour">
  <div class="container">

    <!-- check date of travel -->
    <div class="card-date-centered text-size">
      <h2 style="color:#737863"><b>Select Date of Travel</b></h2>
      <label><b>Tour : <span style="color: #96ad4b">Inthanon National Park</span></b></label>
        <div class="pr-3 pb-3">
          <bs-datepicker-inline [(bsValue)]="bsInlineValue" [minDate]="min_Datetour" [maxDate]="max_Datetour"></bs-datepicker-inline>
        </div>
      </div>


      <!-- check time & privacy -->
      <div class="time-and-type text-size">
        <label><b>Time & Privacy</b></label>
          <ul class="list-group col-12 col-sm-12 col-md-12 col-lg-12 col-xd-12">
            <div class="list-group-centered">
              <li class="list-group-item">
                <p-radio (click)="check_type_private()" shape="curve" color="warning" animation="jelly" >
                  <i p-icon class="material-icons"></i>
                  08:30 A.M. - 17:00 P.M.  <span style="color:red">( Private )</span>
                </p-radio>
              </li>
              <li class="list-group-item">
                  <p-radio (click)="check_type_join()" shape="curve" color="warning" animation="jelly">
                    <i p-icon class="material-icons"></i>
                    08:30 A.M. - 17:00 P.M.  <span style="color:red">( Join )</span>
                  </p-radio>
              </li>
            </div>
          </ul>
      </div>


      <!-- check Participants -->
      <div class="participants text-size" style="margin-top: 5%;">
        <label><b>Participants</b></label>
        <ul class="list-group col-12 col-sm-12 col-md-12 col-lg-12 col-xd-12">
          <div class="list-group-centered">
            <!-- Adult  -->
                <li class="list-group-item">
                  <div class="row">
                      <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xd-6">
                            Adult :  <span style="color:red">{{adult_price}}</span> THB <br>
                            <span *ngIf="message_adult_price" style="font-size:11px">({{message_adult_price}} THB)</span>
                        </div>
                      <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xd-6">
                          <ngx-number-spinner [value]="adult" (change)="change_adult($event)" [min]="min_adult" [max]="max_adult"></ngx-number-spinner>
                      </div>
                  </div>
                </li>
            <!-- Child -->
                <li class="list-group-item">
                  <div class="row">
                      <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xd-6">
                            Child : <span style="color:red">{{child_price}}</span> THB <br>
                            <span *ngIf="message_adult_price" style="font-size:11px">({{message_child_price}} THB)</span>
                        </div>
                      <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xd-6">
                          <ngx-number-spinner height="30px" [value]="child" (change)="change_child($event)" [min]="0" [max]="max_child"></ngx-number-spinner>
                      </div>
                  </div>
                </li>
            <!-- Infant -->
                <li class="list-group-item">
                  <div class="row">
                      <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xd-6">
                            Infant :  
                        </div>
                      <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xd-6">
                          <ngx-number-spinner [value]="infant" (change)="change_infant($event)" [min]="0" [max]="max_infant"></ngx-number-spinner>
                      </div>
                  </div>
                </li>
            <!-- Infant -->
              <li class="list-group-item" style="background-color: #e9ecef;">
                <div class="row">
                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xd-6">
                          <span style="color: red;">Total Booking  </span>
                      </div>
                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xd-6">
                        <span style="color: red; font-size:25px;"><b>{{total_price}}</b></span>
                    </div>
                </div>
              </li>
          </div>
        </ul>
      </div>

      <!-- next to check step sub tour -->
      <div class="btn-centered">
          <button class="btn btn-success" (click)="selectTab(1)" style="color:white" (click)="change_date()" [ngxScrollTo]="'check-date-sub-tour'">continue</button>
      </div>
  </div>  
</section>
</tab>

<!-- <hr> -->

<tab  heading="Static title" >
<section id="check-date-sub-tour">
  <div class="container">

    <!-- check date of travel -->
    <div class="card-date-centered">
      <h2 style="color:#737863"><b>Select Date of Travel</b></h2>
      <label><b>Tour : <span style="color:#96ad4b">City Temple & Museum</span></b></label>
        <div class="pr-3 pb-3">
          <bs-datepicker-inline (bsValueChange)="onValueChange($event)" [(bsValue)]="bsInlineValue_sub" [minDate]="min_Datesubtour" [maxDate]="max_Datesubtour" [datesDisabled]="disabledDates"></bs-datepicker-inline>
        </div>
      </div>

      <!-- check time & privacy -->
      <div class="time-and-type">
        <label>Time & Privacy</label>
          <ul class="list-group col-12 col-sm-12 col-md-12 col-lg-12 col-xd-12">
            <div class="list-group-centered">
              <li *ngIf="type_subTour" class="list-group-item">
                <p-radio name="animation" shape="curve" color="warning" animation="jelly" checked="true">
                  <i p-icon class="material-icons"></i>
                  01.30 P.M. – 05.00 P.M. <span style="color:#54708b">( Private )</span>
                </p-radio>
              </li>
              <li *ngIf="!type_subTour" class="list-group-item">
                  <p-radio name="animation" shape="curve" color="warning" animation="jelly" checked="true">
                    <i p-icon class="material-icons"></i>
                    01.30 P.M. – 05.00 P.M. <span style="color:#54708b">( Join )</span>
                  </p-radio>
              </li>
            </div>
          </ul>
      </div>

      <!-- next to check step tour 2 -->
      <div class="btn-centered">
          <a class="btn btn-success" type="button" style="color:white" (click)="selectTab(0)">previous</a> &nbsp;&nbsp;&nbsp;&nbsp;
          <a class="btn btn-success" type="button" (click)="setDataBooking()" [routerLink]="['/booking/information']" (bsValueChange)="onValueChange($event)"  style="color:white">continue</a>
      </div>
  </div>  
</section>
</tab>
</tabset>
</div>

