import { Component, OnInit } from '@angular/core';
import { IPayPalConfig, ICreateOrderRequest } from 'ngx-paypal';
import { Http, RequestOptions } from '@angular/http';
import { Router, ActivatedRoute } from '@angular/router';
import { map } from "rxjs/operators";
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-update-booking',
  templateUrl: './update-booking.component.html',
  styleUrls: ['./update-booking.component.scss']
})
export class UpdateBookingComponent implements OnInit {

  public payment_id = <any>'';
  public checkpaymentIDactive = <any>'';
  public bsValue_main = new Date();
  public bsValue_sub = new Date();
  public is_edit : boolean = false;
  public min_Datetour = new Date('2021-01-05');
  public max_Datetour = new Date('2021-12-26');
  public min_Datesubtour = new Date();
  public max_Datesubtour = new Date();
  public disabledDates = [];
  public Date_maintour = <any>'';
  public Date_subtour = <any>'';
  public setData = <any>'';
  public checkUpdate = <any>'';
  
  public information_guest = {
    firstname :  <any>'',
    lastname : <any>'',
    email : <any>'',
    phone : <any>''
  };

  public error  = {
    firstname : true,
    lastname : true,
    email : true,
    phone : true
}

  constructor( 
    private http: Http,
    private router: Router,
    private route: ActivatedRoute,
    private datePipe: DatePipe
    ) { }

  
  check_input_info(){
    if(this.information_guest.firstname !== ''){
      this.error.firstname = true;
    }
    if(this.information_guest.lastname !== ''){
      this.error.lastname = true;
    }
    if(this.information_guest.email !== ''){
      this.error.email = true;
    }
    if(this.information_guest.phone !== ''){
      this.error.phone = true;
    }
}

check_info_brfore_sent(){
  if(this.information_guest.firstname == ''){
    this.error.firstname = false;
}
if(this.information_guest.lastname == ''){
    this.error.lastname = false;
}
if(this.information_guest.email == ''){
    this.error.email = false;
}
if(this.information_guest.phone == ''){
    this.error.phone = false;
  }
if(this.information_guest.firstname !== '' && this.information_guest.lastname !== '' && this.information_guest.email !== '' && this.information_guest.phone !== '' ){
    this.setDataupdateinformation();
    this.sendUpdatenewInfo();
    // this.router.navigate(['booking/payment']);
}

}

checkpaymentactive(){
    let payment_ = {
            paymentId : this.payment_id
        }
        //get total Price By Payment_id
        let url = "http://localhost:8000/api/Promotions/Payment/checkPaymentIdActive";
        let options = new RequestOptions();
                return this.http.post(url,payment_,options)
                    .pipe(map(res => res.json()))
                    .subscribe(
                      data => [
                        this.checkpaymentIDactive = data,
                        this.checkActive(),
                        console.log(this.checkpaymentIDactive)
                      ],
                      err => [
                        console.log(err)
                      ]
                    );
}

checkActive(){
  if(this.checkpaymentIDactive.status == "true"){
      this.getdatabooking();
  }else if(this.checkpaymentIDactive.status == "false"){
      alert('ID Booking Not Payment Active !');
      window.location.href='http://tour-in-chiangmai.com/';
  }else{
      alert('ERROR ! Please, Contact IT Team : it@touringcnx.com');
  }
}

getdatabooking(){
      //set Data Value
      this.information_guest.firstname = this.checkpaymentIDactive.firstname;
      this.information_guest.lastname = this.checkpaymentIDactive.lastname;
      this.information_guest.email = this.checkpaymentIDactive.email;
      this.information_guest.phone = this.checkpaymentIDactive.phone;
      this.bsValue_main = new Date(this.checkpaymentIDactive.datetravel_inthanon);
      this.bsValue_sub = new Date(this.checkpaymentIDactive.datetravel_city);
}

is_edit_(){
  this.is_edit = true;
}

onValueChange(){
(async () => {
  await this.delay(1000);
  let Date_ = this.datePipe.transform(this.bsValue_main, 'yyyy-MM-dd');
  let Date__ = this.datePipe.transform(this.bsValue_sub, 'yyyy-MM-dd');
  if(Date_ == this.checkpaymentIDactive.datetravel_inthanon && Date__ ==this.checkpaymentIDactive.datetravel_city){
  }else{
    // ------- DateTime ---------
  let newDate = this.datePipe.transform(this.bsValue_main, 'yyyy-MM-dd');
  this.max_Datesubtour.setDate(this.bsValue_main.getDate()+2);
  this.max_Datesubtour.setMonth(this.bsValue_main.getMonth());
  this.max_Datesubtour.setFullYear(this.bsValue_main.getFullYear());
  this.min_Datesubtour.setDate(this.bsValue_main.getDate()-2);
  this.min_Datesubtour.setMonth(this.bsValue_main.getMonth());
  this.min_Datesubtour.setFullYear(this.bsValue_main.getFullYear());
  this.bsValue_sub = new Date(newDate);
  this.bsValue_sub = new Date(this.bsValue_sub.getFullYear(), this.bsValue_sub.getMonth(), this.bsValue_sub.getDate()+1);
  this.disabledDates.push(new Date(this.datePipe.transform(this.bsValue_main, 'yyyy-MM-dd')));
  // console.log(this.bsValue_main + 'main');
  // console.log(this.bsValue_sub + 'sub');
  }
})();
}

  setDataupdateinformation(){
    this.setData = {
            "paymentId" : {
                  "paymentId" : this.payment_id
                },
            "guestInfo" : {
                  "firstname" : this.information_guest.firstname,
                  "lastname"  : this.information_guest.lastname,
                  "email"     : this.information_guest.email,
                  "tel"       : this.information_guest.phone
                },
            "datetravel" : {
                  "date_travel_inthanon"  : this.bsValue_main.toDateString(),
                  "date_travel_city"      : this.bsValue_sub.toDateString()
            }
    }
    console.log(this.setData);
  }

  sendUpdatenewInfo(){
      let url = "http://localhost:8000/api/Promotions/GetFree/UpdateBooking";
        let options = new RequestOptions();
                return this.http.post(url,this.setData,options)
                    .pipe(map(res => res.json()))
                    .subscribe(
                      data => [
                        this.checkUpdate = data,
                        console.log(this.checkUpdate)
                      ],
                      err => [
                        console.log(err)
                      ]
                    );
  }

delay(ms: number) {
  return new Promise( resolve => setTimeout(resolve, ms) );
}

  ngOnInit(): void {
    this.payment_id = this.route.snapshot.paramMap.get('id');
    this.checkpaymentactive();
  }

}
