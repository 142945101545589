import { BrowserModule } from '@angular/platform-browser';
import { NgModule, NO_ERRORS_SCHEMA  } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormsModule , ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from "@angular/router";
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
// import { HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';
import { HttpModule } from '@angular/http';


import { CheckAvailabilityComponent } from './booking/check-availability/check-availability.component';
import { InthanonAndCityComponent } from './promotion/getfree/inthanon-and-city/inthanon-and-city.component';
import { PaymentComponent } from './booking/payment/payment.component';

import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxPrettyCheckboxModule } from 'ngx-pretty-checkbox';
import { NgxNumberSpinnerModule } from 'ngx-number-spinner';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { InformationGuestComponent } from './booking/information-guest/information-guest.component';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

import { NgxPayPalModule } from 'ngx-paypal';
import { UpdateBookingComponent } from './booking/update-booking/update-booking.component';
import { InvoiceComponent } from './booking/invoice/invoice.component';
import { MenuBarComponent } from './header/menu-bar/menu-bar.component';
import { HomeComponent } from './home/home.component';


@NgModule({
  declarations: [
    AppComponent,
    CheckAvailabilityComponent,
    InthanonAndCityComponent,
    InformationGuestComponent,
    PaymentComponent,
    UpdateBookingComponent,
    InvoiceComponent,
    MenuBarComponent,
    HomeComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BsDatepickerModule.forRoot(),
    BrowserAnimationsModule,
    NgxPrettyCheckboxModule,
    ScrollToModule.forRoot(),
    NgxNumberSpinnerModule,
    TabsModule.forRoot(),
    TooltipModule.forRoot(),
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MatInputModule,
    HttpClientModule,
    MatFormFieldModule,
    NgxPayPalModule,
    HttpModule
  ],
  providers: [DatePipe],
  bootstrap: [AppComponent],
  exports: [AppRoutingModule],
  schemas:[NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
