
<app-menu-bar></app-menu-bar>

<section id="form-promotion">
  <div class="contanier">
    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 breadcrumb_">
      <ul>
        <li class="home"><a href="https://tour-in-chiangmai.com/">Home</a></li>
        <li> / </li>
        <li>Tour Promotion</li>
      </ul>
      <hr>
    </div>
  </div>
  <div class="contanier col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
    <div class="row">
          <div class="col-12 -col-sm-12 col-md-8 col-lg-8 col-xl-8">
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div class="card-body">
                <h1 class="card-title"><b><span style="color:red">BUY 1 GET 1</span>  : Inthanon National Park <span style="color:red"> FREE </span> City Temple & Museum</b></h1><hr>
                <img class="img-promotion" style="margin-top: 2%" src="../../../assets/images/inthanon_free_city.jpg">
              </div>
            </div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <span style="visibility: hidden;">Basic Content</span>
              <div class="tab-bg">
                    <span class="tab-font">Overview</span>
                </div>
                  <div class="tab-inside">
                <!-- ---------------------------- Inthanon ------------------------------ -->
                    <div class="overview_tour">
                      <h3 style="color:#331190">Inthanon National Park</h3><hr>
                      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Heading south of Chiang Mai to Inthanon National Park (Thailand’s highest mountain), 
                        which is a true jewel of natural beauty consisting of rugged mountains terrain blanketed 
                        by lush tropical forests and majestic waterfalls. Stop at the highest peak to see the panoramic 
                        view after that, take a short walk in Ang ka Natural Trail to discover the pure nature with 
                        the cool fresh air where during the winter time you can see the beauty of the Rhododendrons. Then, 
                        continue to the Twin Stupas commemorating the birthday of the King and Queen of Thailand. After that, 
                        visit Hmong Market before visit the King Royal Agricultural Project (or known as the Royal Project). 
                        After lunch, visit Hmong and Karen Villages and on the way back, make a visit to Vachiratharn Waterfall.</p>
                      </div>
                <!-- ---------------------------- City ------------------------------ -->
                      <div class="overview_tour_sub">
                        <h3 style="color:#331190">City Temple & Museum</h3><hr>
                        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Chiang Mai has had a long and independent history, which has a large extent preserved of Thai culture. In addition, 
                          Chiang Mai City also shows the history through its fantastic scenery along the city's moat surrounded by historical and 
                          cultural heritage. Visit the most important temples including Phra Singh Temple (the most beautiful architecture in northern 
                          style reflected from its’ Viharn), Chedi Luang Temple (temple with the Biggest Stupa in Chiang Mai) and Chiang Man Temple 
                          (the oldest temple in Chiang Mai build in the same age of Chiang Mai City). Beside temples, the trip also visit Chiang Mai City 
                          Arts & Cultural Centre known as the center to learn and understand Chiang Mai’s history and culture from the past till now a day.</p>
                        </div>
                  </div>

              <div class="tab-bg">
                    <span class="tab-font">Tour Details</span>
                </div>
                  <div class="tab-inside">
                    <!-- ---------------------------- Inthanon ------------------------------ -->
                    <div id="detail_inthanon">
                      <h3 style="color:#331190">Inthanon National Park</h3>
                      <hr>
                      <div class="row">
                          <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                            <span>08.00 a.m.</span>
                          </div>
                          <div class="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
                            <span>Start pick up from hotels</span>
                          </div>
                          <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                            <span>08.30 a.m.</span>
                          </div>
                          <div class="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
                            <span>Depart for Inthanon National Park Stop at the highest peak and pay a respect to the shrine of king</span><br>
                            <span>Inthawichayanon (the earlier King of Chiang Mai)</span><br>
                            <span>Explore Ang ka Natural Trial with Rhododendrons</span><br>
                            <span>Visit King and Queen’ Stupa</span><br>
                            <span>Visit Hmong Market</span>
                          </div>
                          <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                            <span>08.00 a.m.</span>
                          </div>
                          <div class="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
                            <span>Lunch at Royal Project's Restaurant (Set Menu)</span><br>
                            <span>Visit Mae Klang Luang Karen Villages with taste of fresh coffee</span><br>
                            <span>Visit Vachiratharn Waterfall</span>
                          </div>
                          <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                            <span>05.00 p.m.</span>
                          </div>
                          <div class="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
                            <span>Arrive in Chiang Mai and drop at hotels</span>
                          </div>
                      </div>
                  </div>
                  <!-- ---------------------------- City ------------------------------ -->
                  <div id="detail_inthanon" class="detail-city-form">
                    <h3 style="color:#331190">City Temple & Museum</h3>
                    <hr>
                    <div class="row">
                        <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                            <span>08.00 a.m. / 01.00 p.m.</span>
                        </div>
                        <div class="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
                          <span>Start pick up from hotels</span>
                        </div>
                        <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                          <span>08.30 a.m.</span>
                        </div>
                        <div class="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
                          <span>Depart for Chiang Mai City Arts and Culture Centre</span><br>
                          <span>Visit Chiang Mai City Arts and Cultural Centre</span><br>
                          <span>Visit Phra Singh Temple</span><br>
                          <span>Visit Chedi Luang Temple</span><br>
                          <span>Visit Chiang Man Temple</span>
                        </div>
                        <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                          <span>12.00 a.m. / 05.00 p.m.</span>
                        </div>
                        <div class="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
                          <span>Transfer back to hotels</span><br>
                        </div>
                    </div>
                </div>
                 </div>

                 <div class="tab-bg">
                      <span class="tab-font">Tour Includes & Tour Excludes</span>
                    </div>
                  <span class="tab-inside">Round trip transfer from/to the hotel, Transportation for tour (VIP 9 seats air-con van), Professional English speaking guide, Admission fees, Lunch, Drinking water & cold towel for refreshment, Travel accident insurance</span>

                  <span class="tab-inside">Other optional tour or meals than mentioned in the program, Personal expenses</span>

 
              <div class="tab-bg">
                    <span class="tab-font">Cancellation</span>
                </div>
                  <ul  class="tab-inside">
                    <li><fa-icon [icon]="faCaretSquareRight"></fa-icon> Cancellation Charge will be applied as follows
                      <ul style="font-size:13px;">
                        <li><fa-icon [icon]="faCaretRight"></fa-icon> Cancel the trip <span style="color:red;">after reservation has been made and over 7 days</span> prior the trip or service start, 25% cancellation charge</li><br>
                        <li><fa-icon [icon]="faCaretRight"></fa-icon> Cancel the trip <span style="color:red;">less than 7 days but over 5 days</span> prior the trip or service start, 50% cancellation</li><br>
                        <li><fa-icon [icon]="faCaretRight"></fa-icon> Cancel the trip <span style="color:red;">less than 5 days but over 3 days</span> prior the trip or service start, 75% cancellation charge</li><br>
                        <li><fa-icon [icon]="faCaretRight"></fa-icon> Cancel the trip <span style="color:red;">less than 3 days or 72 hours</span> prior the service start, 100% cancellation charge</li>
                      </ul>
                    </li>
                    <li>
                      <fa-icon [icon]="faCaretSquareRight"></fa-icon><span style="color:red;"> Last Minute Cancelled</span> and <span style="color:red;">No Shows</span>, 100% of total price
                    </li><br>
                    <li>
                      <fa-icon [icon]="faCaretSquareRight"></fa-icon><span style="color:red;"> Unused features are non-refundable</span>
                    </li>
                  </ul>
  
              </div>
          </div>
          <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 booking-padding">
            <app-check-availability></app-check-availability>
          </div>
    </div>
  </div>
</section>