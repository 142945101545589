import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { InthanonAndCityComponent } from './promotion/getfree/inthanon-and-city/inthanon-and-city.component';
import { InformationGuestComponent } from './booking/information-guest/information-guest.component';
import { PaymentComponent } from './booking/payment/payment.component';
import { UpdateBookingComponent } from './booking/update-booking/update-booking.component';
import { InvoiceComponent } from './booking/invoice/invoice.component';
import { HomeComponent } from './home/home.component'


const routes: Routes = [
  // {
  //   path : '' ,
  //   component : InthanonAndCityComponent
  // },
  {
    path : '' ,
    component : HomeComponent
  },
  {
    path: 'promotion/getfree/inthanon-and-city',
    component: InthanonAndCityComponent
  },
  {
    path: 'booking/information',
    component: InformationGuestComponent
  },
  {
    path: 'booking/payment/:id',
    component: PaymentComponent
  },
  {
    path: 'booking/updatebooking/:id',
    component: UpdateBookingComponent
  },
  {
    path: 'booking/invoice/:id',
    component: InvoiceComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
