
<app-menu-bar></app-menu-bar>
<section id="payment">
  <div class="contanier">
    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 breadcrumb_">
      <ul>
        <li class="home"><a href="https://tour-in-chiangmai.com/">Home</a></li>
        <li> / </li>
        <li>Tour Promotion</li>
        <li> / </li>
        <li><a [routerLink]="['/']">Inthanon National Park And City Temple & Museum</a></li>
        <li> / </li>
        <li><a [routerLink]="['/booking/information']">Information</a></li>
      </ul>
      <hr>
</div>
</div><br>
  <div class="contanier inside-info-payment">
      <div class="info-payment">
          <p>The listed price does not include a 500THB & 1,000THB surcharge for pick up and drop off in hotel outside the city centre
            (6 km. from the 3 king monument). The pick up surcharge is required by our local supplier and is not within our control.
          </p><br>
          <p><b>Terms and Conditions</b></p>
          <span>All sales are final and incur 100% cancellation penalties.</span><br>
          <span>Any change less than 12 hours before the departure time, will be charged THB500.- per person.</span>
      </div>
  </div>
  <div class="container" style="text-align: center; margin-top: 3%; color:red;">
    <div class="card">
      <div class="card-body">
      <div class="note-payment">
          <span>Noted : After your payment via Paypal, the system will redirect to our website.</span><br>
          <span>We will get back the confirmation to you via email.</span>
      </div>
      </div>
    </div>
  </div>
  <div class="container" style="text-align: center; margin-top:5%;">
    <ngx-paypal [config]="payPalConfig"></ngx-paypal>
    <hr>
  </div>
  <div style="text-align: center;">
    <p>14 1st Floor, Ratchadamnoen Rd., Soi 5, Sriphum, Muang, Chiang Mai 50200 Thailand</p>
    <p>Mobile : +66(0)88 258 5817 Tel : +66(0)53 289 644 Fax : +66(0)53 289 659</p>
    <p>E-mail : reservations@touringcnx.com, touringcenter@hotmail.com</p>
    <p>Operating Hour : 08.00 - 20.00 (Daily)</p>
  </div>
</section>