import { Component, OnInit } from '@angular/core';
import { faCoffee } from '@fortawesome/free-solid-svg-icons';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { faCaretSquareRight } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-inthanon-and-city',
  templateUrl: './inthanon-and-city.component.html',
  styleUrls: ['./inthanon-and-city.component.scss']
})
export class InthanonAndCityComponent implements OnInit {


  faCoffee = faCoffee;
  faCaretRight = faCaretRight;
  faCaretSquareRight = faCaretSquareRight;
  constructor() { }


  ngOnInit(): void {
  }

}
